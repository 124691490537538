<template>
  <el-dialog
    v-model="winShow"
    width="900px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="t('assign.application_detail')"
    @close="closeWin">
    <custem-detail :loading="detailLoading" :descriptions="descriptions">
      <template #send_email>
        <!-- 发送邮箱 -->
        <send-mail ref="sendMailRef" :detail="applicatDetail" />
      </template>
      <template #send_issuse>
        <!-- 授权下发状态 -->
        <send-issuse ref="sendIssuseRef" :detail="applicatDetail" />
      </template>
    </custem-detail>
    <!-- 添加接收邮箱 未分配的时候显示 -->
    <el-form
      v-if="$userRole(['Enterprise']) && applicatDetail.status === 0 && openType === 'confirm'"
      ref="formMail"
      :model="emailData"
      :rules="emailRules"
      label-width="170px"
      label-position="right"
      class="margin-t2">
      <div v-for="(item, index) in formEmailItems" :key="index">
        <el-form-item :label="index === 0 ? t('assign.add_mail') : ''" :prop="item.prop" required>
          <div class="add-mail">
            <el-input v-model="emailData[item.prop]" :placeholder="t('form.inputPlaceholder')" />
            <span @click="delMail(item.prop, index)">{{ t('form.delete') }}</span>
          </div>
        </el-form-item>
      </div>
      <el-button
        :style="{ marginLeft: formEmailItems.length ? '170px' : '' }"
        @click="addMail" type="primary"
        plain>＋{{ t('assign.add_mail') }}</el-button>
    </el-form>
    <!-- 下发申请时选择的表格 -->
    <order-list ref="orderTable" @closeWin="closeWin" />
    <!-- 分配历史表格/详情显示 -->
    <div v-if="$userRole(['Enterprise']) && openType !== 'confirm'">
      <el-tabs v-model="selectTab">
        <el-tab-pane
          v-if="user_type !== 'scan'"
          :label="t('assign.project_apply_history')" :name="1">
          <custem-table
            :tableLoading="tableLoading"
            :tableInfo="applyTable"
            @getList="getAssignmentList"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="user_type === 'scan' ? t('assign.user_apply_history') : t('assign.historical_order_allocation')"
          :name="2">
          <custem-table
            :tableLoading="tableLoading"
            :tableInfo="assignTable"
            @getList="getAssignOrderList"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <template v-if="$userRole(['Enterprise']) && applicatDetail.status === 0 && openType === 'confirm'">
          <el-button type="primary" @click="processApplicate">{{ t('assign.assign') }}</el-button>
          <el-button type="danger" @click="emit('closeApplication', applicatDetail)">{{ t('form.reject') }}</el-button>
        </template>
        <el-button @click="closeWin">{{ t('form.close') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, inject, reactive, getCurrentInstance, nextTick, watch } from 'vue'
import SendMail from '../../components/send_email.vue'
import SendIssuse from '../../components/send_issuse.vue'
import { AuthManagement } from '@/utils/api'
import { detailInfo, assignTableTemplate, applyTableTemplate, scanDescriptions } from '../data.js'
import OrderList from './orderList'
import store from '@/store'

const sendMailRef = ref()
const t = inject('t')
const { proxy } = getCurrentInstance()
const emit = defineEmits(['closeApplication', 'getList']) // 拒绝分配
const openType = ref('detail') // 默认打开弹框的类型是分配或者详情
const user_type = ref(store.state.users.tags === 1 ? 'scan' : 'sub_user')
const selectTab = ref(store.state.users.tags === 1 ? 2 : 1)
const detailLoading = ref(true)
const tableLoading = ref(true)
const winShow = ref(false)
const descriptions = reactive(user_type.value === 1 ? scanDescriptions : detailInfo) // 详情参数
const applicatDetail = ref({}) // 申请详情信息
const formEmailItems = ref([])
const orderTable = ref() // 下发选择订单
const emailRules = ref({})
const emailData = ref({
  mail0: ''
})
const formMail = ref()
// 继续添加邮箱
const addMail = () => {
  const newItem = { prop: `mail${formEmailItems.value.length}` }
  formEmailItems.value.push(newItem)
  // 更新校验规则
  emailRules.value[newItem.prop] = [
    { required: true, message: t('form.emptyErrMsg'), trigger: "blur" },
    { required: true, message: t('form.error_mail'), trigger: "blur", type: 'email' }
  ]
}
// 删除绑定邮箱
const delMail = (item, index) => {
  delete emailData.value[item]
  formEmailItems.value.splice(index, 1)
}
watch(() => selectTab.value, val => {
  if (!Object.keys(applicatDetail.value).length || !val) return
  if (val === 1) {
    // 项目申请列表
    getAssignmentList(applicatDetail.value.project.id, applicatDetail.value.id)
  } else {
    getAssignOrderList(applicatDetail.value.id)
  }
}, { deep: true })

// 获取项目申请历史列表
const getAssignmentList = async (row_id, ass_id) => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.ApplicationList({ filter_project_id: row_id })
    applyTable.data = res.items
    applyTable.totalPage = res.total
    applyTable.pageSize = res.per_page
    if (applyTable.data.length) {
      applyTable.data = applyTable.data.filter(item => item.id !== ass_id)
    }
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 获取分配历史订单列表
const getAssignOrderList = async (row_id) => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.AllocationAssignmentList({ filter_allocation_id: row_id })
    assignTable.data = res.items
    assignTable.totalPage = res.total
    assignTable.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 获取分配详情接口
const getApplicationDetail = async (id) => {
  detailLoading.value = true
  try {
    // 获取申请详情
    const applicatInfo = await AuthManagement.ApplicationDetail(id)
    applicatDetail.value = applicatInfo
    // 获取申请下的授权信息
    const assignInfo = await AuthManagement.AllocationAssignmentList({ filter_allocation_id: applicatInfo.id })
    applicatDetail.value.lic_secret = assignInfo.items.length ? assignInfo.items[0].assignment.lic_secret : '-'
    descriptions.forEach(item => {
      if (item.prop === 'source') { // 申请来源
        item.value = Number(applicatDetail.value[item.prop]) === 1 ? t('system.name') : Number(applicatDetail.value[item.prop]) === 4 ? t('assign.International') : t('assign.scan_code')
      } else if (item.prop === 'project_name') { // 项目
        item.value = applicatDetail.value.project && applicatDetail.value.project.name ? applicatDetail.value.project.name : '-'
        item.hidden = openType.value !== 'confirm' && user_type.value === 'scan'
      } else if (item.prop === 'create_user') { // 所属子用户
        item.value = applicatDetail.value.create_user && applicatDetail.value.create_user.name ? applicatDetail.value.create_user.name : '-'
        item.hidden = !!!proxy.$userRole(['Enterprise']) && openType.value !== 'confirm'
      } else if (item.prop === 'product_name') { // 产品名称
        item.value = applicatDetail.value.project ? `${applicatDetail.value.project.product.deployment.name}/${applicatDetail.value.project.product.category.name}/${applicatDetail.value.project.product.name}` : '-'
      } else if (item.prop === 'content_value') { // 申请数量
        item.value = applicatDetail.value.content ? `${proxy.$numberToCurrencyNo(applicatDetail.value.content.hosts)} ${t('unit.ge')}` : '-'
        item.hidden = openType.value !== 'confirm' && user_type.value ==='scan'
      } else if (item.prop === 'status') { // 审核状态
        item.hidden = openType.value === 'confirm'
        item.value = proxy.$formatApplicatState(applicatDetail.value.status, 'html')
      } else if (item.prop === 'saas_user') { // 产品用户名
        item.value = applicatDetail.value.project && applicatDetail.value.project.product.params.auth_name ? applicatDetail.value.project.product.params.auth_name : '-'
      } else if (item.prop === 'send_issuse') { // 授权下发状态
        item.hidden = ![0, 8, 9].indexOf(applicatDetail.value.status)
      } else if (item.prop === 'enterprise_name') { // 企业用户名
        item.value = applicatDetail.value.params && applicatDetail.value.params.enterprise_name || '-'
      } else if (item.prop === 'sub_user_name') { // 姓名
        item.value = applicatDetail.value.params && applicatDetail.value.params.user_name || '-'
      } else if (item.prop === 'phone') { // 联系方式
        item.value = applicatDetail.value.params && applicatDetail.value.params.phone || '-'
      } else if (item.prop === 'is_first') { // 是否首次申请
        item.value = applicatDetail.value.is_first ? `<span class="default">${t('table.yes')}</span>` : `<span class="faild">${t('table.no')}</span>`
      } else if (item.prop === 'use_period') { // 使用周期
        item.value = applicatDetail.value.use_period ? `${proxy.$numberToCurrencyNo(applicatDetail.value.use_period)} ${Number(applicatDetail.value.use_period) > 1 ? t('system.days') : t('system.day')}` : '-'
      } else if (item.prop === 'comment') { // 备注
        item.hidden = openType.value !== 'confirm'
        item.value = applicatDetail.value[item.prop]
      } else {
        item.value = applicatDetail.value[item.prop] || '-'
      }
    })
    await nextTick()
    sendMailRef.value.sendMailList() // 查询发送邮箱
    if (proxy.$userRole(['Enterprise'])) {
      getAssignmentList(applicatDetail.value.project.id, applicatDetail.value.id)
    }
    detailLoading.value = false
  } catch (error) {
    detailLoading.value = false
  }
}

// 下发授权
const processApplicate = () => {
  if (!formMail.value) return
  formMail.value.validate(valid => {
    if (valid) {
      const mailList = Object.values(emailData.value)
      const mailString = mailList.join(',')
      if (orderTable.value) {
        orderTable.value.openWin(applicatDetail.value, mailString)
      }
    }
  })
}
// 申请历史表格
const applyTable = reactive(applyTableTemplate(proxy, getApplicationDetail, getAssignmentList, Number(applicatDetail.value.source) === 1 ? 'scan' : null))
// 分配历史订单表格
const assignTable = reactive(assignTableTemplate(proxy))
// 打开弹框
const openWin = async (data) => {
  winShow.value = true
  openType.value = data.openType
  getApplicationDetail(data.id)
}

// 关闭弹框
const closeWin = () => {
  if (openType.value === 'confirm' && formMail.value) {
    formMail.value.clearValidate()
    formMail.value.resetFields()
  }
  emailData.value = {}
  formEmailItems.value = []
  emailRules.value = {}
  winShow.value = false
  selectTab.value = 1
  emit('getList')
}

defineExpose({
  openWin: openWin,
  closeWin: closeWin
})
</script>

<style lang="scss">
.mail_status {
  display: flex;
  align-items: center;
}
</style>