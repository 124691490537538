<template>
  <el-dialog
    v-model="winShow"
    width="900px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="t('assign.sub_user_select_order')"
    @close="closeWin">
    <div class="form" style="width: 100% !important;">
      <order-listAssi ref="order_list" @pushOrder="pushOrder"></order-listAssi>
      <!-- 授权生效时间 -->
      <div v-if="formData.length" class="form-item margin-t4">
        <span class="margin-t1">
          {{  t('assign.license_start_at') }}
        </span>
        <el-radio-group v-model="formVal.timeType" class="margin-l2">
          <div>
            <el-radio label="1">{{ t('assign.effective_immediately') }}</el-radio>
            <div class="start_at">
              <el-radio label="2">{{ t('assign.scheduled') }}</el-radio>
              <el-date-picker
                v-if="formVal.timeType === '2'"
                v-model="formVal.start_at"
                :disabled-date="disabledDate"
                :placeholder="t('assign.select_effective_time')"
                type="datetime" />
            </div>
          </div>
        </el-radio-group>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button
          :loading="submitLoading"
          @click="onSubmit"
          type="primary">{{ t('form.submit') }}</el-button>
        <el-button @click="closeWin">{{ t('form.cancel') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance, inject, reactive, watch, nextTick } from 'vue'
import { AuthManagement } from '@/utils/api'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'

const t = inject('t')
const { proxy } = getCurrentInstance()
const emit = defineEmits(['closeWin'])
const formData = ref([])
const order_list = ref()
const getData = ref({})
const winShow = ref(false)
const mails = ref('')
const orderList = ref([])
const submitLoading = ref(false)
// 授权生效时间
const formVal = reactive({
  timeType: '1',
  start_at: proxy.$startTime()
})
const endTime = ref(null)
// 禁用可选时间
const disabledDate = (date) => {
  return new Date(date).getTime() > endTime.value || new Date(date).getTime() < new Date().getTime()
}
// 确认
const onSubmit = async () => {
  if (!!!formData.value.length) {
    ElMessage.warning(t('assign.ressiue_order'))
    return
  }
  if (formVal.timeType === '2' && !!!formVal.start_at) {
    ElMessage.warning(t('assign.select_license_start_at'))
    return
  }
  submitLoading.value = true
  try {
    await AuthManagement.ApplicationIssue(getData.value.id, {
      status: 1,
      assignments: formData.value,
      custom_mail: mails.value
    })
    ElMessage.success(t('assign.issued_successfully'))
    closeWin()
    emit('closeWin')
    submitLoading.value = false
  } catch (error) {
    submitLoading.value = false
  }
}

// 关闭弹框
const closeWin = () => {
  winShow.value = false
  order_list.value.clearOrderList()
  formVal.timeType = '1'
  formVal.start_at = proxy.$startTime(null, false)
}

// 打开弹框
const openWin = async (data, mailList) => {
  getData.value = data;
  mails.value = mailList
  winShow.value = true
  await nextTick()
  order_list.value.getOrderList({
    filter_assignment_project_id: data.project.id,
    filter_product_id: data.project.product.id
  })
}

// 接收多选数据
const pushOrder = (data) => {
  orderList.value = data
  formData.value = []
  data.forEach(item => {
    formData.value.push({
      content: { hosts: item.number },
      order_id: item.id,
      start_at: proxy.$startTime(null, false),
      project_id: getData.value.project.id,
      product_id: getData.value.project.product.id,
      lic_key: getData.value.lic_key,
      failback_key: getData.value.failback_key
    })
  })
}
// 监听订单，设置定时生效时间的开始时间
watch(() => orderList.value, val => {
  if (!val || !val.length) return
  endTime.value = Math.min.apply(Math, val.map(item => {
    return new Date(dayjs(item.expired_at).format('YYYY-MM-DD HH:mm:ss')).getTime()
  }))
}, { immediate: true, deep: true })
// 监听生效时间
watch(() => formVal.start_at, val => {
  if (val && formData.value.length) {
    const date = val.toISOString()
    formData.value.forEach(item => {
      item.start_at = `${date.substring(0, date.length - 5)}Z`
    })
  }
}, { immediate: true, deep: true })
watch(() => formVal.timeType, val => {
  if (val && val === '1') formData.value.forEach(item => {
    item.start_at = proxy.$startTime(null, false)
  })
}, { immediate: true, deep: true })

defineExpose({
  openWin: openWin
})
</script>

<style lang="scss" scoped>
.form-item {
  display: flex;
  align-items: flex-start;
}
</style>